import React, { useState, useEffect } from 'react';
import './PracticeAreas.css';
import Banner from '../../components/banner/banner';
import { motion } from 'framer-motion';
import { useLanguage } from '../../context/LanguageContext';
import { fetchEntries } from '../../contenfulService';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const PracticeAreas = () => {
	const [content, setContent] = useState(null);
	const { language } = useLanguage();
	const locale = language === 'en' ? 'en-US' : 'es';

	useEffect(() => {
		fetchEntries({
			content_type: 'practiceAreas',
			order: 'sys.createdAt',
			locale,
		})
			.then((response) => {
				setContent(response.items);
			})
			.catch((error) => {
				console.error('Error fetching content', error);
			});
	}, [locale]);

	const bannerTitle = content
		? content[0].fields.practiceAreasH1
		: 'Loading...';
	const defendH2 = content
		? content[0].fields.practiceAreasH2First
		: 'Loading...';
	const defendText =
		content && content[0].fields.defendText
			? documentToReactComponents(content[0].fields.defendText)
			: 'Loading...';
	const experienceH2 = content
		? content[0].fields.practiceAreasH2Second
		: 'Loading...';
	const experienceText =
		content && content[0].fields.experienceText
			? documentToReactComponents(content[0].fields.experienceText)
			: 'Loading...';

	const renderBulletPoints = (bulletPoints) => {
		if (!bulletPoints) return null;
		return documentToReactComponents(bulletPoints);
	};

	const practiceBoxes = content && content[0].fields.individualPracticeArea ? content[0].fields.individualPracticeArea : [];

	return (
		<div>
				<Banner imgSrc='./assets/grapes.jpeg' title={bannerTitle} />
			<br />
			<motion.div
				className='content-motion'
				initial={{ x: -50, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				transition={{ duration: 1, delay: 0.5 }}
			>
				<div className='text-box pl-4 pr-4'>
					<h2>{defendH2}</h2>
					<br />
					{defendText}
					<br />

					<h2>{experienceH2}</h2>
					<br />
					{experienceText}
					<br />
					<h2>{content && content[0].fields.ourPracticeAreasTitle}</h2>
				</div>
				</motion.div>
				<br/>
				<motion.div
				className='content-motion'
				initial={{ x: -50, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				transition={{ duration: 1, delay: 1 }}
			>
				<div className='flex flex-wrap'>
					{practiceBoxes.length > 0 ? (
						practiceBoxes.map((area, index) => (
							area.fields.bulletPointsForArea ? (
								<div
									key={index}
									className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-offwhite p-4 rounded shadow-dark mb-4'
								>
									<h2 className='font-bold text-darker mb-2'>{area.fields.areaTitle}</h2>
									{renderBulletPoints(area.fields.bulletPointsForArea)}
								</div>
							) : (
								null
							)
						))
					) : null }
					
				<div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-offwhite p-4 rounded shadow-dark mb-4'>
					{practiceBoxes.filter(area => !area.fields.bulletPointsForArea).map((area, index) => (
				 <h2 key={index} className="font-bold" >{area.fields.areaTitle}</h2>
	  )) }
				</div></div>	
				</motion.div>	
		</div>
	);
};

export default PracticeAreas;