import React, { useState, useEffect } from 'react';
import Banner from '../../components/banner/banner';
import { useLanguage } from '../../context/LanguageContext';
import { fetchEntries } from '../../contenfulService';
import './contact.css';
import { motion } from 'framer-motion';
const Contact = () => {
  const [content, setContent] = useState(null);
  const { language } = useLanguage();
  const locale = language === 'en' ? 'en-US' : 'es';

  useEffect(() => {
    fetchEntries({
      content_type: 'contactUs',
      order: 'sys.createdAt',
      locale,
    })
      .then((response) => {
        setContent(response.items);
      })
      .catch((error) => {
        console.error('Error fetching content', error);
      });
  }, [locale]);

  const contactContent = content && content[0].fields;

  return (
		<div>
      {contactContent && (

				<>
          <Banner imgSrc='/assets/grapes.jpeg' title={contactContent.contactTitle || 'Loading...'} />
          <br />
          <div className="mt-0 ml-8 mr-8 mb-8">
					<motion.div
					initial={{ x: -50, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					transition={{ duration: 1, delay: 0.5}}>
          <div className='flex-wrap contact-text text-center text-gray-700 bg-offwhite rounded-lg shadow-md'>
            {contactContent.contactText && (
              <>
                <p>{contactContent.contactText}
                <a href={`tel:${contactContent.phoneNumber}`} className='text-blue hover:underline'>
                  {contactContent.phoneNumber}
                </a></p>
              </>
            )}
            {contactContent.contactFax && (
							<>
                <br />
                <p>{contactContent.contactFax}</p>
              </>
            )}

            {contactContent.openingHoursTitle && (
							<>
                <br />
                <h1><b>{contactContent.openingHoursTitle}</b></h1>
              </>
            )}

            {contactContent.openingHoursText && (
							<>
                <p>{contactContent.openingHoursText}</p>
                <br />
              </>
            )}

            {contactContent.locationTitle && (
							<>
                <h1><b>{contactContent.locationTitle}</b></h1>
              </>
            )}

            {contactContent.locationInfo && (
							<>
                <p>{contactContent.locationInfo}</p>
                <br />
              </>
            )}

          </div>
					</motion.div>
          </div>
        </>
      )}


		</div>
  );
};

export default Contact;
