import React from "react";
import './hero.css';

const Hero = ({ imgSrc, title, children }) => {
    return (
        <div className="hero">
            <img src={imgSrc} alt="Hero" className="hero-image" />
            <div className="hero-text">
                {/* <h1>{title}</h1> */}
                {children}
            </div>
        </div>
    );
}

export default Hero;
