import React, { useState, useEffect } from 'react';
import Hero from "../../components/hero/hero";
import { motion } from 'framer-motion';
import { fetchEntries } from '../../contenfulService';
import { useLanguage } from "../../context/LanguageContext";
import InfoBoxes from '../../components/infoBoxes/InfoBoxes';

const Home = () => {
  const [content, setContent] = useState(null);
  const { language } = useLanguage();
  const locale = language === 'en' ? 'en-US' : 'es';

  useEffect(() => {
    fetchEntries({ content_type: 'landingImageOverlayText', order: 'sys.createdAt', locale })
        .then((response) => {
            setContent(response.items);
        })
        .catch((error) => {
            console.error('Error fetching content', error);
        });
  }, [locale]);

  const bannerTitle = content ? content[0].fields.landingImgOverlayH1 : 'Loading...';
  const bannerText = content ? content[0].fields.landingImgOverlayH2 : 'Loading...';
  const infoBoxesData = content ? content[0].fields.infoBox : [];
  const landingImageUrl = content ? content[0].fields.landingImage.fields.file.url : 'Loading...';

  return (
    <section className="mainWrapper">
    <section className="contentWrapper">
    <motion.div
    initial={{ x: 0, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    transition={{ duration: 1 }}
    >

      <Hero className="about-hero-text" imgSrc={landingImageUrl}>
      <motion.h1
  initial={{ opacity: 0 }}
  animate={{  y: -15, opacity: 1 }}
  transition={{ duration: 1 }}
>
  {bannerTitle}
</motion.h1>
<motion.p
  initial={{ opacity: 0 }}
  animate={{ y: -15, opacity: 1 }}
  transition={{ duration: 1 }}
>
  {bannerText}
</motion.p>


      </Hero>
      <motion.div
       initial={{ x: -50, opacity: 0 }}
       animate={{ x: 0, opacity: 1 }}
       transition={{ duration: 1, delay: 1 }}
      >
        <div className="ml-4 mr-4">
        <InfoBoxes infoBoxes={infoBoxesData} />
        </div>
      </motion.div>
      <br/>

    </motion.div>
    </section>
    </section>
  );
};

export default Home;
