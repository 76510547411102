import React, { useState, useEffect } from 'react';
import Banner from '../../components/banner/banner';
import { useLanguage } from '../../context/LanguageContext';
import { fetchEntries } from '../../contenfulService';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './attorneys.css';
import { motion } from 'framer-motion';
const Tab = ({ name, onClick, isActive }) => (
	<button
		className={`tablinks ${
			isActive ? 'bg-blue-100 text-blue' : 'text-gray-500 hover:bg-gray-100'
		}
                  px-4 py-2 font-medium text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300`}
		onClick={onClick}
	>
		{name}
	</button>
);

const Attorneys = () => {
	const [content, setContent] = useState(null);
	const [activeTab, setActiveTab] = useState(null);
	const { language } = useLanguage();
	const locale = language === 'en' ? 'en-US' : 'es';

	useEffect(() => {
		fetchEntries({
			content_type: 'ourTeamPage',
			order: 'sys.createdAt',
			locale,
		})
			.then((response) => {
				const sortedAttorneys =
					response.items[0].fields.individualAttorneys.sort((a, b) => {
						const dateA = a.fields.barDate || '9999-12-31';
						const dateB = b.fields.barDate || '9999-12-31';
						return dateA.localeCompare(dateB);
					});

				setContent([
					{
						...response.items[0],
						fields: {
							...response.items[0].fields,
							individualAttorneys: sortedAttorneys,
						},
					},
				]);
				if (sortedAttorneys.length > 0) {
					setActiveTab(sortedAttorneys[0].fields.attorneyName);
				}
			})
			.catch((error) => {
				console.error('Error fetching content', error);
			});
	}, [locale]);

	const renderBio = (bio) => {
		if (!bio) return null;
		return documentToReactComponents(bio);
	};

	const bannerTitle = content ? content[0].fields.ourTeamTitle : 'Loading...';

	return (
		<div className='attorney-gallery'>
				<Banner imgSrc='/assets/grapes.jpeg' title={bannerTitle} />
			<div className='container mx-auto px-4 mb-8'>
				<motion.div
					initial={{ x: -50, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					transition={{ duration: 1, delay: 0.5 }}
					className='flex flex-wrap mx-2 mt-4'
				>
					{content &&
						content[0].fields.individualAttorneys.map((attorney) => (
							<Tab
								key={attorney.sys.id}
								name={attorney.fields.attorneyName}
								isActive={attorney.fields.attorneyName === activeTab}
								onClick={() => setActiveTab(attorney.fields.attorneyName)}
							/>
						))}
				</motion.div>
				<motion.div
					initial={{ x: -50, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					transition={{ duration: 1, delay: 1 }}
					className='tab-content mt-8'
				>
					{content &&
						content[0].fields.individualAttorneys.map((attorney) => (
							<div
								key={attorney.sys.id}
								className={`attorney-container ${
									attorney.fields.attorneyName === activeTab
										? 'block'
										: 'hidden'
								}`}
							>
								{attorney.fields.attorneyName === activeTab && (
									<div className='p-4 bg-offwhite border border-gray-200 rounded-lg shadow-lg'>
										<div className='mb-2'>
											<section className='photoContainer'>
												<div className='namePhoto'>
													{attorney.fields.employeePhoto && (
														<img
															className='headShot w-20 h-20'
															src={
																attorney.fields.employeePhoto.fields.file.url
															}
															alt='Attorney'
														/>
													)}
													<h1 className='text-xl font-semibold  '>
														{attorney.fields.attorneyName}
													</h1>
												</div>
											</section>
										</div>
										{attorney.fields.attorneyBio1 && (
											<div className='text-black'>
												{renderBio(attorney.fields.attorneyBio1)}
											</div>
										)}
										{attorney.fields.attorneyBio2 && (
											<>
												<br />
												<div className='text-black '>
													{renderBio(attorney.fields.attorneyBio2)}
												</div>
											</>
										)}
										{attorney.fields.attorneyBio3 && (
											<>
												<br />
												<div className='text-black'>
													{renderBio(attorney.fields.attorneyBio3)}
												</div>
											</>
										)}
										{attorney.fields.attorneyBio4 && (
											<>
												<br />
												<div className='text-black'>
													{renderBio(attorney.fields.attorneyBio4)}
												</div>
											</>
										)}
										{attorney.fields.credentialsTitle && (
											<div>
												<br />
												<h2 className='text-black'>
													{renderBio(attorney.fields.credentialsTitle)}
												</h2>
											</div>
										)}
										{attorney.fields.education && (
											<>
												<br />
												<div className='text-black'>
													{renderBio(attorney.fields.education)}
												</div>
											</>
										)}
										{attorney.fields.admissions && (
											<>
												<br />
												<div className='text-black'>
													{renderBio(attorney.fields.admissions)}
												</div>
											</>
										)}
										{attorney.fields.professionalAdmissionsAndMembership && (
											<>
												<br />
												<div className='text-black'>
													{renderBio(
														attorney.fields.professionalAdmissionsAndMembership
													)}
												</div>
											</>
										)}
									</div>
								)}
							</div>
						))}
				</motion.div>
			</div>
		</div>
	);
};

export default Attorneys;
