import React from "react";
import './banner.css'; // Import the styling for the Banner component

const Banner = ({ imgSrc, title }) => {
    return (
        <div className="banner">
            <img src={imgSrc} alt="Banner" className="banner-image" /> 
            <div className="banner-text">
                <h1>{title}</h1>
            </div>
        </div>
    );
}

export default Banner;