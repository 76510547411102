import React, { useState, useEffect } from 'react';
import Banner from '../banner/banner';
import { motion } from 'framer-motion';
import { useLanguage } from '../../context/LanguageContext';
import { fetchEntries } from '../../contenfulService';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './cases.css';

const Cases = () => {
	const [content, setContent] = useState(null);
	const { language } = useLanguage();
	const locale = language === 'en' ? 'en-US' : 'es';

	useEffect(() => {
		fetchEntries({
			content_type: 'casesPage',
			order: 'sys.createdAt',
			locale,
		})
			.then((response) => {
				setContent(response.items);
			})
			.catch((error) => {
				console.error('Error fetching content', error);
			});
	}, [locale]);

	const bannerTitle = content ? content[0].fields.casesTitle : 'Loading...';
	const cases = content ? content[0].fields.individualCase : [];

	return (
		<>
			<section className='casesContainer mb-4'>
					<Banner imgSrc='./assets/grapes.jpeg' title={bannerTitle} />
				<br />
				<motion.div
					className='content-motion'
					initial={{ x: -50, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					transition={{ duration: 1, delay: .5 }}
				>
					<div className='text-box pl-4 pr-4 mb-8 ml-8 mr-8'>
						{content ? (
							<>
							{documentToReactComponents(content[0].fields.aboutOurCases)}
							<br />
							{documentToReactComponents(content[0].fields.aboutOurCases2)}
							</>
						) : (
							<p>Loading...</p>
						)}
						</div>
						</motion.div>
						<br/>
					<motion.div
					className='content-motion'
					initial={{ x: -50, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					transition={{ duration: 1, delay: 1 }}
					>
					<div className='flex flex-col items-center pl-4 pr-4 mb-4'>
						{cases.length > 0 ? (
							cases.map((caseItem, index) => (
								<div
									key={index}
									className='individualCase w-full sm:w-1/2 md:w-1/3 lg:w-1/3 align-items:center text-black text-xl bg-offwhite rounded p-4 shadow-dark mb-4'
								>
									<center><h2><i>{caseItem.fields.caseName}</i></h2>
									{documentToReactComponents(
										caseItem.fields.individualCaseInfo
									)}</center>
								</div>
							))
						) : (
							<p>Loading...</p>
						)}
					</div>

				</motion.div>
				
			</section>
		</>
	);
};

export default Cases;
