import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import PracticeAreas from "./pages/practice-areas/PracticeAreas";
import Contact from "./pages/contact/Contact";
import FreeConsultation from "./pages/free-consult/FreeConsultation";
import Attorneys from "./pages/attorneys/Attorneys";
import { LanguageProvider } from "./context/LanguageContext";
import Disclaimer from "./pages/disclaimer/Disclaimer";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import './app.css';
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/footer";
import Cases from "./components/cases/cases";



function App() {
  return (
    <LanguageProvider>
    <Router>
      <div className="app-container">
      <Navbar />
      <div className="mainContentContainer">
      <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" component={About} />
      <Route path="/practice-areas" component={PracticeAreas} />
      <Route path="/contact" component={Contact} />
      <Route path="/attorneys" component={Attorneys} />
      <Route path="/free-consult" component={FreeConsultation} />
      <Route path="/disclaimer" component={Disclaimer} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/cases" component={Cases} />
    </Switch>
    </div>
    <Footer />
    </div>
    </Router>
    </LanguageProvider>
  );
}

export default App;
