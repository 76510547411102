import React, { useState, useEffect } from 'react';
import Banner from '../../components/banner/banner';
import { fetchEntries } from '../../contenfulService';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './about.css';
import { motion } from 'framer-motion';
import { useLanguage } from '../../context/LanguageContext';

const About = () => {
	const [content, setContent] = useState(null);
  const { language } = useLanguage();
  const locale = language === 'en' ? 'en-US' : 'es';

  useEffect(() => {
    fetchEntries({ content_type: 'about', order: 'sys.createdAt', locale })
        .then((response) => {
            setContent(response.items);
        })
        .catch((error) => {
            console.error('Error fetching content', error);
        });
}, [locale]);


const bannerTitle = content ? content[0].fields.aboutOverlay : 'Loading...';
const aboutImageUrl = content ? content[0].fields.aboutImage.fields.file.url : 'Loading...';

	return (
		<>
			<div>
					<>
  <Banner imgSrc='/assets/grapes.jpeg' title={bannerTitle} />
  <motion.div
    initial={{ x: -50, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    transition={{ duration: 1, delay: 0.5 }}
    className="aboutContainer"
  >
    
    <div className="text-box mx-9 pl-4 pr-4 about-text ">
      {content ? (
        <>
          {documentToReactComponents(content[0].fields.aboutParagraph1)}
          <br />
          {documentToReactComponents(content[0].fields.aboutParagraph2)}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  
   < div className="aboutImageContainer">
      <img src={aboutImageUrl} alt="About" className="about-image" />
    </div>
  </motion.div>
</>
			</div>
		</>
	);
};

export default About;
