
import React, { useState, useEffect } from 'react';
import { useLanguage } from "../../context/LanguageContext";
import { fetchEntries } from '../../contenfulService';
import './language.css'

const LanguageButton = () => {
  const [content, setContent] = useState(null);
  const { language, toggleLanguage } = useLanguage();
  const locale = language === 'en' ? 'en-US' : 'es';

  useEffect(() => {
		fetchEntries({
			content_type: 'contactUs',
			order: 'sys.createdAt',
			locale,
		})
			.then((response) => {
				setContent(response.items);
			})
			.catch((error) => {
				console.error('Error fetching content', error);
			});
	}, [locale]);

  const phone = content ? content[0].fields.phoneNumber : 'Loading...';
	const fax = content ? content[0].fields.contactFax : 'Loading...';

  return (
    <div className="topContainer w-full shadow-lg">
    <div className='container1 w-full'>
    <span>Phone: <a href={phone} className='hover:underline' style={{ color: '#4077bb' }}>
            {phone}
          </a> | </span><span className='faxNum'>{fax}</span>
    </div>

    <div className="container2 w-full bg-transparent focus:outline-none">
    <button onClick={toggleLanguage}>
      {language === 'en' ? 'Español' : 'English'}
    </button></div>
    </div>
  );
}

export default LanguageButton;
