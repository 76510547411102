import React, { useState, useEffect } from 'react';
import "./footer.css";
import { Link } from 'react-router-dom';
import { fetchEntries } from '../../contenfulService';
import { useLanguage } from "../../context/LanguageContext";

const Footer = () => {
    const [content, setContent] = useState(null);
    const { language, toggleLanguage } = useLanguage();
    const locale = language === 'en' ? 'en-US' : 'es';

useEffect(() => {
    fetchEntries({ content_type: 'footer', order: 'sys.createdAt', locale })
        .then((response) => {
            setContent(response.items);
        })
        .catch((error) => {
            console.error('Error fetching content', error);
        });
}, [locale]);

const disclaimer = content ? content[0].fields.disclaimerLink : 'Loading...';
const privacy= content ? content[0].fields.privacyPolicy : 'Loading...';
const allRights = content ? content[0].fields.allRightsReserved : 'Loading...';
    return (
        <footer className='bg-darker' style={{ color: 'white', padding: '1rem', justifyContent: 'center' }}>
            <p className="text-center">
                Copyright © 2024 Mallison & Martinez | {allRights} | <Link to="/disclaimer" style={{ color: 'white' }}>{disclaimer}</Link>  | <Link to="/privacy-policy" style={{ color: 'white' }}> {privacy}</Link> | <button onClick={toggleLanguage} style={{ color: 'white', backgroundColor: 'transparent', border: 'none' }}>{language === 'en' ? 'Español' : 'English'}</button>
            </p>
        </footer>
    );
}

export default Footer;
