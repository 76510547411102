import React, { useState, useEffect } from 'react';
import { fetchEntries } from '../../contenfulService';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useLanguage } from '../../context/LanguageContext';

const PrivacyPolicy = () => {
	const [content, setContent] = useState(null);
	const { language } = useLanguage();
	const locale = language === 'en' ? 'en-US' : 'es';

	useEffect(() => {
		fetchEntries({
			content_type: 'privacyPolicy',
			order: 'sys.createdAt',
			locale,
		})
			.then((response) => {
				setContent(response.items);
			})
			.catch((error) => {
				console.error('Error fetching content', error);
			});
	}, [locale]);
	const pageTitle = content && content[0].fields.privacyPolicyTitle;
	const text1 =
		content && documentToReactComponents(content[0].fields.privacyField1);
	const text2 =
		content && documentToReactComponents(content[0].fields.privacyField2);
	const text3 =
		content && documentToReactComponents(content[0].fields.privacyField3);
	const text4 =
		content && documentToReactComponents(content[0].fields.privacyField4);
	return (
		<>

			<div className='flex flex-col items-center justify-left min-h-screen w-full'>
				<br />
				<div className='px-6 py-4 text-justify text-gray-700 bg-offwhite rounded-xl shadow-dark mr-6 ml-6'>
					<h1 className='text-center text-2xl font-bold'>
						<b>{pageTitle}</b>
					</h1>
					<br />
					{text1}
					<br />
					{text2}
					<br />
					{text3}
					<br />
					{text4}
				</div>
				<br />
			</div>

		</>
	);
};

export default PrivacyPolicy;
