import React, { useState, useEffect } from 'react';
import './free.css';
import { fetchEntries } from '../../contenfulService';
import { useLanguage } from '../../context/LanguageContext';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const FreeConsultation = () => {
	const [content, setContent] = useState(null);
	const { language } = useLanguage();
	const locale = language === 'en' ? 'en-US' : 'es';

	useEffect(() => {
		fetchEntries({ content_type: 'freeConsult', order: 'sys.createdAt', locale })
			.then((response) => {
				setContent(response.items);
			})
			.catch((error) => {
				console.error('Error fetching content', error);
			});
	}, [locale]);

  const title = content ? content[0].fields.freeConsultTitle : 'Loading...';
  const subTitle = content? content[0].fields.freeConsultSubtitle : 'Loading...';
  const contact = content? content[0].fields.contactUs : 'Loading...';
  const adDisclaimer = content? documentToReactComponents(content[0].fields.adDisclaimer) : 'Loading...';
  const call = content? content[0].fields.callUs : 'Loading...';
  const freeImgUrl = content? content[0].fields.freeConsultImg.fields.file.url : 'Loading...';

	return (
  <>

<div
  className="relative bg-cover bg-center bg-no-repeat w-full h-screen"
  style={{ backgroundImage: `url(${freeImgUrl})` }}
>
  <div className="backdrop-filter backdrop-blur-sm bg-opacity-50 bg-black">
  <div className="text-white p-4 md:p-8 lg:p-12">
  <h1 className="text-4xl font-bold mb-4">{title}</h1>
  <h2 className="text-2xl mb-4">{subTitle}</h2>
  <div>{contact}</div>
  <div className="mt-8 text-m">
    1939 Harrison St # 730
    <br />
    Oakland, CA 94612
    <br />
    Phone: (510) 832-9999
  </div>
  <a href="tel:+15108329999" className="inline-block bg-primary-500 underline text-blue-600 text-lg py-2 px-4 rounded mt-4">
    {call}
  </a>
  <div className="mt-4 text-sm adDisclaimer lg:w-1/2 md:w-2/3">{adDisclaimer}</div>
</div>
  </div>
</div>

</>
	);
};

export default FreeConsultation;
