import React, { useState, useEffect } from 'react';
import { fetchEntries } from '../../contenfulService';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useLanguage } from '../../context/LanguageContext';

const Disclaimer = () => {
  const [content, setContent] = useState(null);
  const { language } = useLanguage();
  const locale = language === 'en' ? 'en-US' : 'es';

  useEffect(() => {
    fetchEntries({ content_type: 'disclaimerPage', order: 'sys.createdAt', locale })
        .then((response) => {
            setContent(response.items);
        })
        .catch((error) => {
            console.error('Error fetching content', error);
        });
}, [locale]);

  const text1 = content && documentToReactComponents(content[0].fields.textBlock1)
  const text2 = content && documentToReactComponents(content[0].fields.textBlock2)
  return (
    <>
    <div className="flex-grow flex flex-col items-center min-h-screen">
        <br/>
    <br/>
      <div className="px-6 py-4 max-w-prose text-justify text-gray-700 bg-offwhite rounded-xl shadow-dark">
        {text1}
        <br/>
       {text2}
        </div>
        <br/>
    </div>
    </>
  );
}

export default Disclaimer;
