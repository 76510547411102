// Navbar.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file
import { GiHamburgerMenu } from 'react-icons/gi'; // Import the hamburger icon
import { fetchEntries } from '../../contenfulService';
import { useLanguage } from '../../context/LanguageContext';
import LanguageButton from '../languageButton/language';


const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [content, setContent] = useState(null);
	const { language } = useLanguage();
	const locale = language === 'en' ? 'en-US' : 'es';

	const home = content ? content[0].fields.homeLink : 'Loading...';
	const about = content ? content[0].fields.aboutLink : 'Loading...';
	const practiceAreas = content
		? content[0].fields.practiceAreasLink
		: 'Loading...';
	const ourTeam = content ? content[0].fields.ourTeamLink : 'Loading...';
	const contact = content ? content[0].fields.contactLink : 'Loading...';
	const casesLink = content ? content[0].fields.casesLink : 'Loading...';
	useEffect(() => {
		fetchEntries({ content_type: 'navBar', order: 'sys.createdAt', locale })
			.then((response) => {
				setContent(response.items);
			})
			.catch((error) => {
				console.error('Error fetching content', error);
			});
	}, [locale]);

	const handleLinkClick = () => {
		setIsOpen(false);
	};

	return (
		<>
			<section className='navbar'>
				<div className='relative z-10 w-full'>
					<div className='w-full'>
						<LanguageButton />
					</div>
				</div>
				<nav>
					<div className='logo-container'>
						<Link to='/'>
							<img src='./assets/website-logo-res.png' alt='Logo' className='ml-6 mb-2'/>
						</Link>
					</div>
					<button className='hamburger' onClick={() => setIsOpen(!isOpen)}>
						<GiHamburgerMenu />
					</button>
					<div className={`menu ${isOpen ? 'show' : ' '}`}>
						<button className='exit-button' onClick={handleLinkClick}>
							X
						</button>
						<Link to='/' onClick={handleLinkClick}>{home}</Link>
						<Link to='/about' onClick={handleLinkClick}>{about}</Link>
						<Link to='/attorneys' onClick={handleLinkClick}>{ourTeam}</Link>
						<Link to='/practice-areas' onClick={handleLinkClick}>{practiceAreas}</Link>
						<Link to='/cases' onClick={handleLinkClick}>{casesLink}</Link>
						<Link to='/contact' onClick={handleLinkClick} >{contact}</Link>
					</div>
				</nav>
			</section>
		</>
	);
};

export default Navbar;
