const contentful = require('contentful');

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: 'master', // defaults to 'master' if not set
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});


const fetchEntry = (entryId) => {
  return client.getEntry(entryId);
};


const fetchEntries = (query) => {
  return client.getEntries(query);
};



module.exports = {
  fetchEntry,
  fetchEntries
};
