import React from 'react';
import { Link } from 'react-router-dom';
const InfoBoxes = ({ infoBoxes }) => {

	return (
		<div className='flex flex-wrap justify-around mt-10'>
			{infoBoxes.length > 0 ? (
				infoBoxes.map((box, index) => (
					<div
						key={index}
						className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 bg-offwhite p-4 rounded shadow-dark mb-4'
					>
						<h2 className='font-bold text-darker mb-2'>{box.fields.infoTitle}</h2>
						<p className='mb-4'>{box.fields.infoText}</p>
						 <Link to={box.fields.linkUrl}><button className='bg-darker hover:#4077bb text-white px-4 py-2 rounded'>
							{box.fields.learnMoreButton}
						</button></Link>
					</div>
				))
			) : (
				<div>Loading...</div>
			)}
		</div>
	);
};

export default InfoBoxes;
